import React, { Suspense, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import routes from '../route';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMembers, fetchUserData } from '../store/actions-member';
import PrivateRoute from '../components/PrivateRoute';
import { api } from '../store/api';
import { fetchAdminData, fetchAdmins } from '../store/actions-admin';
import { PICPA_TOKEN, ROLE } from '../store/constant';
import * as actionTypes from '../store/actions';
import { fetchEvents } from '../store/actions-events';
import { isEventInTheFuture } from '../pages/Events/Fullcalendar';
import { fetchPendingTransactions } from '../store/actions-transactions';

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader,
});

const App = () => {
  const auth = useSelector((state) => state.auth);
  const events = useSelector((state) => state.events);
  const pendingTransactions = useSelector((state) => state.pendingTransactions);
  const { token, role } = auth;
  const dispatch = useDispatch();
  const token_local = localStorage.getItem(PICPA_TOKEN);
  const role_local = localStorage.getItem(ROLE);

  useEffect(() => {
    if (token_local) {
      dispatch({
        type: actionTypes.SET_USER,
        data: { token: token_local, role: role_local },
      });
    }
  }, [dispatch, token_local, role_local]);
  //useEffect to change the header 'authenticate' to the value of token stored in the reducer. I don't know if this will work haha
  useEffect(() => {
    if (token) {
      api.defaults.headers.common = { authenticate: `${token}` };
    }
  }, [token]);
  //fetch members list
  useEffect(() => {
    if (token) {
      dispatch(fetchMembers());
    }
  }, [dispatch, token]);
  //member: fetch user data and events
  useEffect(() => {
    if (role === 'member' && token) {
      dispatch(fetchUserData());
      dispatch(fetchEvents());
    }
  }, [role, dispatch, token]);
  //admin: fetch admin data, admin list, and events.
  useEffect(() => {
    if (role !== 'member' && token) {
      dispatch(fetchAdminData());
      if (role === 'head-admin' || role === 'staff-admin') {
        dispatch(fetchAdmins());
      }
      if (role === 'event-admin' || role === 'staff-admin') {
        dispatch(fetchEvents());
      }
      if (role === 'treasurer-admin') {
        dispatch(fetchPendingTransactions());
      }
    }
  }, [role, dispatch, token]);
  //DOM manipulation use effect to change the value of upcoming events count
  //note: the events list must be an array.
  useEffect(() => {
    let upcomingEvents;
    let badges;
    let badge;
    upcomingEvents = events.filter((event) => isEventInTheFuture(event.eDate));
    badges = document.getElementsByClassName('label pcoded-badge label-danger');
    badge = badges[0];

    try {
      if (role === 'treasurer-admin') {
        badge.innerHTML = pendingTransactions.length;
      } else {
        badge.innerHTML = upcomingEvents.length;
      }
    } catch (err) {
      //option: do something
    }
  }, [events, pendingTransactions, role]);

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  return (
    <Aux>
      <Suspense fallback={<Loader />}>
        <Switch>
          {menu}
          <PrivateRoute path="/" component={AdminLayout} />
        </Switch>
      </Suspense>
    </Aux>
  );
};

export default App;
