import React from 'react';
import { PlusLg } from 'react-bootstrap-icons';
import classes from './Add.module.css';

const Add = ({ title, onClick, style }) => {
  return (
    <div
      style={style}
      className={classes['add-new-transaction']}
      onClick={onClick}
    >
      <div>
        {' '}
        <PlusLg className={classes.icon} size={50} />
        <p style={{ color: 'black' }}>{title}</p>
      </div>
    </div>
  );
};

export default Add;
