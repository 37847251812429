import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { PICPA_TOKEN } from '../store/constant';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth.token);
  const token_local = localStorage.getItem(PICPA_TOKEN);
  return (
    <Route
      {...rest}
      render={(props) => {
        return token != null || token_local != null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/sign-in" />
        );
      }}
    />
  );
};

export default PrivateRoute;
