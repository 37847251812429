import * as actionTypes from './actions';
import { api } from './api';
import { INVALID_TOKEN, NO_ADMIN_FOUND, ROLE } from './constant';

export const createAdminLog = async (action, Admin_idAdmin) => {
  const body = {
    Admin_idAdmin,
    action,
  };

  const iCreate = async () => {
    await api
      .post('/adminLogs/create', body)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  await iCreate();
};

export const fetchAdminLogs = async (action) => {};
