import * as actionTypes from './actions';
import { api } from './api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  APPROVE_MEMBER,
  INVALID_TOKEN,
  NO_USER_FOUND,
  PICPA_TOKEN,
  ROLE,
} from './constant';
import { createAdminLog } from './actions-logs';
const MySwal = withReactContent(Swal);

export const signIn = (email, password, navigateToProfile) => {
  return async (dispatch) => {
    //Fire a sweet alert
    MySwal.fire({
      title: 'Logging in...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();

        //execute log in here
        const login = async () => {
          await api
            .post('/member/login', { email: email, password: password })
            .then((res) => {
              //succesful request
              console.log(res.data);
              const data = res.data;
              if (data.message) {
                //invalid username or password
                Swal.close({ isSuccessful: false, message: data.message });
              } else {
                //sign in successful
                Swal.close({ isSuccessful: true });
                dispatch({
                  type: actionTypes.SET_USER,
                  data: { token: data.token, role: 'member' },
                });
                localStorage.setItem(PICPA_TOKEN, data.token);
                localStorage.setItem(ROLE, 'member');
                navigateToProfile();
              }
            })
            .catch((err) => {
              //login failed
              Swal.close({ isSuccessful: false, message: err });
            });
        };

        await login();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        console.log('successful');
      }
    });
  };
};

export const fetchMembers = () => {
  return async (dispatch) => {
    const iFetchMembers = async () => {
      await api
        .get('/member')
        .then((res) => {
          //succesful request
          const members = res.data;
          if (members === NO_USER_FOUND) {
            dispatch({ type: actionTypes.SET_MEMBERS, data: [] });
          } else {
            dispatch({ type: actionTypes.SET_MEMBERS, data: members });
          }

          console.log('Members fetched successfuly!');
        })
        .catch((err) => {
          //error
          console.log(err);
          //if invalid token, send the user back to default page
          if (err === INVALID_TOKEN) {
            dispatch({ type: actionTypes.RESET_USER });
          }
        });
    };

    await iFetchMembers();
  };
};

export const fetchUserData = () => {
  return async (dispatch) => {
    const ifetchUserData = async () => {
      await api
        .get(`/member/profile`)
        .then((res) => {
          //succesful request
          const userData = res.data;
          //if no user found
          if (userData === NO_USER_FOUND) {
            return dispatch({ type: actionTypes.RESET_USER });
          }
          dispatch({ type: actionTypes.SET_USER_DATA, data: userData });
          console.log('User data fetched successfuly!');
        })
        .catch((err) => {
          //error
          console.log(err);
          //if invalid token, send the user back to default page
          if (err === INVALID_TOKEN) {
            dispatch({ type: actionTypes.RESET_USER });
          }
        });
    };

    await ifetchUserData();
  };
};

export const fetchMember = async (id) => {
  const iFetchMember = async () => {
    await api
      .get(`/member/getone/${id}`)
      .then((res) => {
        //succesful request
        const member = res.data;
        console.log(member);
        //return the result
      })
      .catch((err) => {
        //error
        console.log(err);
        //if invalid token, send the user back to default page
      });
  };

  await iFetchMember();
};

//Problem: The password is set to the ecrypted string password
export const updateMember = (
  data,
  currentUserId,
  newPassword = null,
  currentAdminName = null,
  members
) => {
  console.log(currentUserId);
  //Clean the data first before puting it as the body;
  let cleanedData = { ...data };
  delete cleanedData['cpaPrcID'];
  delete cleanedData['photo'];
  delete cleanedData['createdAt'];
  delete cleanedData['updatedAt'];
  delete cleanedData['loginSession'];
  delete cleanedData['password'];
  if (newPassword !== null) {
    cleanedData.password = newPassword;
  }
  //check if the user is updating his/her own acc.
  //true === member || false === admin is updating the acc
  const isUpdatingOwnAccount = data.idUser === currentUserId;
  //Create new members list and dispatch to the members redux store
  const memberIndex = members.findIndex((item) => item.idUser === data.idUser);
  let updatedMembers;
  const existingMember = members[memberIndex];
  const updatedMember = {
    ...existingMember,
    ...data,
  };
  updatedMembers = [...members];
  updatedMembers[memberIndex] = updatedMember;
  return async (dispatch) => {
    MySwal.fire({
      title: 'Updating profile...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();
        const iUpdateMember = async () => {
          await api
            .put(`/member/update`, cleanedData)
            .then(async (res) => {
              //succesful request
              const memberData = res.data;
              console.log(memberData);
              if (isUpdatingOwnAccount) {
                dispatch({
                  type: actionTypes.SET_USER_DATA,
                  data: { ...data },
                });
              } else {
                await createAdminLog(
                  `${currentAdminName} updated the account of member ${data.idUser}.`,
                  currentUserId
                );
              }
              dispatch({
                type: actionTypes.SET_MEMBERS,
                data: updatedMembers,
              });
              Swal.close({
                isSuccessful: true,
                message: 'Your profile has been updated.',
              });
            })
            .catch((err) => {
              //error
              console.log(err);
              //if invalid token, send the user back to default page
              if (err === INVALID_TOKEN) {
                dispatch({ type: actionTypes.RESET_USER });
              }
              //display the error to the user
              Swal.close({ isSuccessful: false, message: err });
            });
        };
        await iUpdateMember();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};

export const deleteMember = (id, members, currentAdminId, currentAdminName) => {
  const newMembersList = members.filter((member) => member.idUser !== id);
  return async (dispatch) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this member?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        MySwal.fire({
          title: 'Deleting member...',
          showCancelButton: false,
          allowOutsideClick: false,
          timer: 100000000000,
          stopKeydownPropagation: true,
          didOpen: async () => {
            Swal.showLoading();
            const iDeleteMember = async () => {
              await api
                .delete('/member/delete', { data: { idUser: id } })
                .then(async (res) => {
                  //succesful request
                  const data = res.data;
                  console.log(`Member successfully deleted: ${data}`);
                  dispatch({
                    type: actionTypes.SET_MEMBERS,
                    data: newMembersList,
                  });
                  Swal.close({
                    isSuccessful: true,
                    message: 'User successfuly deleted!',
                  });
                  await createAdminLog(
                    `${currentAdminName} deleted member ${id}`,
                    currentAdminId
                  );
                })
                .catch((err) => {
                  //error
                  console.log(`Delete member failed, error message: ${err}`);
                  //if invalid token, send the user back to default page
                  if (err === INVALID_TOKEN) {
                    dispatch({ type: actionTypes.RESET_USER });
                  }
                  Swal.close({ isSuccessful: false, message: err });
                });
            };
            await iDeleteMember();
          },
        }).then((result) => {
          if (!result.isSuccessful) {
            MySwal.fire({
              title: 'Error',
              text: result.message,
              type: 'error',
            });
          } else if (result.isSuccessful) {
            MySwal.fire({
              title: 'Successful',
              text: result.message,
              type: 'success',
            });
          }
        });
      } else {
        return MySwal.fire('', 'Action cancelled!', 'error');
      }
    });
  };
};

export const approveOrDeclineMember = (
  memberData,
  membersList,
  currentAdminName,
  currentAdminId,
  action
) => {
  // the new body
  let newStatus;
  action === APPROVE_MEMBER
    ? (newStatus = 'approved')
    : (newStatus = 'declined');
  const cleanedData = { ...memberData, status: newStatus };
  delete cleanedData['cpaPrcID'];
  delete cleanedData['photo'];
  delete cleanedData['createdAt'];
  delete cleanedData['updatedAt'];
  delete cleanedData['loginSession'];
  delete cleanedData['password'];
  // the new members list data.
  const memberIndex = membersList.findIndex(
    (item) => item.idUser === memberData.idUser
  );
  let updatedMembers;
  const existingMember = membersList[memberIndex];
  const updatedMember = {
    ...existingMember,
    status: newStatus,
  };
  updatedMembers = [...membersList];
  updatedMembers[memberIndex] = updatedMember;

  return async (dispatch) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${action} this member application?`,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        if (action === 'decline') {
          MySwal.fire({
            input: 'textarea',
            inputLabel: 'Message',
            inputPlaceholder: 'Your membership is declined due to...',
            inputAttributes: {
              'aria-label':
                'Type your message here: Your membership is declined due to...',
            },
            showCancelButton: true,
          }).then(({ value }) => {
            if (value) {
              console.log('testing');
              MySwal.fire({
                title: 'Updating member application status...',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 100000000000,
                stopKeydownPropagation: true,
                didOpen: async () => {
                  console.log('testing2');
                  Swal.showLoading();
                  const iApproveDeclineAction = async () => {
                    await api
                      .put('/member/updatemembership', {
                        ...cleanedData,
                        status: 'declined',
                        reason: value,
                      })
                      .then(async (res) => {
                        //succesful request
                        dispatch({
                          type: actionTypes.SET_MEMBERS,
                          data: updatedMembers,
                        });
                        Swal.close({
                          isSuccessful: true,
                          message: `Member application ${action}d!`,
                        });
                        await createAdminLog(
                          `${currentAdminName} ${action}d the member application of ${memberData.fname} ${memberData.lname}.`,
                          currentAdminId
                        );
                      })
                      .catch((err) => {
                        //error
                        //if invalid token, send the user back to default page
                        if (err === INVALID_TOKEN) {
                          dispatch({ type: actionTypes.RESET_USER });
                        }
                        Swal.close({ isSuccessful: false, message: err });
                      });
                  };
                  await iApproveDeclineAction();
                },
              }).then((result) => {
                if (!result.isSuccessful) {
                  MySwal.fire({
                    title: 'Error',
                    text: result.message,
                    type: 'error',
                  });
                } else if (result.isSuccessful) {
                  MySwal.fire({
                    title: 'Successful',
                    text: result.message,
                    type: 'success',
                  });
                }
              });
            } else {
              return MySwal.fire(
                '',
                'Input decline reason to continue.',
                'error'
              );
            }
          });
        } else {
          MySwal.fire({
            title: 'Updating member application status...',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 100000000000,
            stopKeydownPropagation: true,
            didOpen: async () => {
              console.log('testing2');
              Swal.showLoading();
              const iApproveDeclineAction = async () => {
                await api
                  .put('/member/updatemembership', {
                    ...cleanedData,
                    status: 'approved',
                  })
                  .then(async (res) => {
                    //succesful request
                    dispatch({
                      type: actionTypes.SET_MEMBERS,
                      data: updatedMembers,
                    });
                    Swal.close({
                      isSuccessful: true,
                      message: `Member application ${action}d!`,
                    });
                    await createAdminLog(
                      `${currentAdminName} ${action}d the member application of ${memberData.fname} ${memberData.lname}.`,
                      currentAdminId
                    );
                  })
                  .catch((err) => {
                    //error
                    //if invalid token, send the user back to default page
                    if (err === INVALID_TOKEN) {
                      dispatch({ type: actionTypes.RESET_USER });
                    }
                    Swal.close({ isSuccessful: false, message: err });
                  });
              };
              await iApproveDeclineAction();
            },
          }).then((result) => {
            if (!result.isSuccessful) {
              MySwal.fire({
                title: 'Error',
                text: result.message,
                type: 'error',
              });
            } else if (result.isSuccessful) {
              MySwal.fire({
                title: 'Successful',
                text: result.message,
                type: 'success',
              });
            }
          });
        }

        //
      } else {
        return MySwal.fire('', 'Action cancelled!', 'error');
      }
    });
  };
};

export const createMember = (data, idAdmin, adminName) => {
  //Clean the data first before puting it as the body;
  let cleanedData = { ...data };
  delete cleanedData['confirmPassword'];

  return async (dispatch) => {
    MySwal.fire({
      title: 'Creating member account...',
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        MySwal.showLoading();
        const iCreateMember = async () => {
          await api
            .post(`/member/admincreate`, cleanedData)
            .then(async (res) => {
              //succesful request
              console.log(res);
              await createAdminLog(
                `${adminName} created a member account.`,
                idAdmin
              );
              Swal.close({
                isSuccessful: true,
                message: 'Member account has been created.',
              });
            })
            .catch((err) => {
              //error
              console.log(err);
              //if invalid token, send the user back to default page
              if (err === INVALID_TOKEN) {
                dispatch({ type: actionTypes.RESET_USER });
              }
              //display the error to the user
              Swal.close({ isSuccessful: false, message: err });
            });
        };
        await iCreateMember();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};

export const resetPassword = (email) => {
  return async (dispatch) => {
    MySwal.fire({
      title: 'Resetting password...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();
        const sendRequest = async () => {
          await api
            .put('/member/forgot', { email })
            .then((res) => {
              const message = res.data.message;
              Swal.close({
                isSuccessful: true,
                message: message,
              });
            })
            .catch((err) => {
              Swal.close({
                isSuccessful: true,
                message: err,
              });
            });
        };
        await sendRequest();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};

//This is the template for the Loading Sweet Alert!!!

// MySwal.fire({
//   title: 'Updating profile...',
//   showCancelButton: false,
//   allowOutsideClick: false,
//   timer: 100000000000,
//   stopKeydownPropagation: true,
//   didOpen: async () => {
//     Swal.showLoading();
//   },
// }).then((result) => {
//   if (!result.isSuccessful) {
//     MySwal.fire({
//       title: 'Error',
//       text: result.message,
//       type: 'error',
//     });
//     console.log('failed');
//   } else if (result.isSuccessful) {
//     MySwal.fire({
//       title: 'Successful',
//       text: result.message,
//       type: 'success',
//     });
//     console.log('successful');
//   }
// });
