import * as actionTypes from './actions';
import { api } from './api';
import { createAdminLog } from './actions-logs';
import { INVALID_TOKEN } from './constant';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export const fetchPendingTransactions = () => {
  return async (dispatch) => {
    const iFetchPending = async () => {
      await api
        .get('/pendingpayment')
        .then((res) => {
          const data = res.data;
          const list = data.data;
          dispatch({ type: actionTypes.SET_TRANSACTIONS, data: list });
        })
        .catch((err) => {
          //if invalid token, send the user back to default page
          if (err === INVALID_TOKEN) {
            dispatch({ type: actionTypes.RESET_USER });
          }
        });
    };

    await iFetchPending();
  };
};

export const deletePendingTransaction = (
  idPendingPayment,
  currentAdminName,
  currentAdminId,
  pendingList
) => {
  const newList = pendingList.filter(
    (item) => item.idPendingPayment !== idPendingPayment
  );
  return async (dispatch) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to decline this transaction?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        MySwal.fire({
          title: 'Deleting transaction...',
          showCancelButton: false,
          allowOutsideClick: false,
          timer: 100000000000,
          stopKeydownPropagation: true,
          didOpen: async () => {
            Swal.showLoading();
            const iDeleteTransaction = async () => {
              await api
                .delete('/pendingpayment/delete', {
                  data: { idPendingPayment: idPendingPayment },
                })
                .then(async (res) => {
                  //succesful request
                  dispatch({
                    type: actionTypes.SET_TRANSACTIONS,
                    data: newList,
                  });
                  Swal.close({
                    isSuccessful: true,
                    message: 'Transaction successfuly deleted!',
                  });
                  await createAdminLog(
                    `${currentAdminName} deleted a transaction.`,
                    currentAdminId
                  );
                })
                .catch((err) => {
                  //error
                  //if invalid token, send the user back to default page
                  if (err === INVALID_TOKEN) {
                    dispatch({ type: actionTypes.RESET_USER });
                  }
                  Swal.close({ isSuccessful: false, message: err });
                });
            };
            await iDeleteTransaction();
          },
        }).then((result) => {
          if (!result.isSuccessful) {
            MySwal.fire({
              title: 'Error',
              text: result.message,
              type: 'error',
            });
          } else if (result.isSuccessful) {
            MySwal.fire({
              title: 'Successful',
              text: result.message,
              type: 'success',
            });
          }
        });
      } else {
        return MySwal.fire('', 'Action cancelled!', 'error');
      }
    });
  };
};

//Create payment transaction function

// export const createPaymentTransaction = (data) => {
//   return async (dispatch) => {
//     MySwal.fire({
//       title: 'Creating payment transaction...',
//       showCancelButton: false,
//       allowOutsideClick: false,
//       timer: 100000000000,
//       stopKeydownPropagation: true,
//       didOpen: async () => {
//         Swal.showLoading();
//         const sendRequest = async () => {
//           await api
//             .post('/pendingpayment/create', data)
//             .then((res) => {
//               const message = res.data.message;
//               Swal.close({ isSuccessful: true, message: message });
//             })
//             .catch((err) => {
//               if (err === INVALID_TOKEN) {
//                 dispatch({ type: actionTypes.RESET_USER });
//               }
//               Swal.close({ isSuccessful: false, message: err });
//             });
//         };
//         await sendRequest();
//       },
//     }).then((result) => {
//       if (!result.isSuccessful) {
//         MySwal.fire({
//           title: 'Error',
//           text: result.message,
//           type: 'error',
//         });
//         console.log('failed');
//       } else if (result.isSuccessful) {
//         MySwal.fire({
//           title: 'Successful',
//           text: result.message,
//           type: 'success',
//         });
//         console.log('successful');
//       }
//     });
//   };
// };
