import withReactContent from 'sweetalert2-react-content';
import * as actionTypes from './actions';
import { api } from './api';
import { createAdminLog } from './actions-logs';
import { INVALID_TOKEN, NO_EVENTS_FOUND } from './constant';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);

export const fetchEvents = () => {
  return async (dispatch) => {
    const iFetchEvents = async () => {
      await api
        .get('/event')
        .then((res) => {
          const events = res.data;
          console.log(res);
          if (events === NO_EVENTS_FOUND) {
            dispatch({ type: actionTypes.SET_EVENTS, data: [] });
          } else {
            dispatch({ type: actionTypes.SET_EVENTS, data: events });
          }
        })
        .catch((err) => {
          //if invalid token, send the user back to default page
          if (err === INVALID_TOKEN) {
            dispatch({ type: actionTypes.RESET_USER });
          }
          dispatch({ type: actionTypes.SET_EVENTS, data: [] });
        });
    };

    await iFetchEvents();
  };
};

export const createEvent = (eventData, currentAdminId, currentAdminName) => {
  let cleanedData;
  let sTime;
  let eTime;
  sTime = eventData.startTime.format('hh:mm a');
  eTime = eventData.endTime.format('hh:mm a');
  cleanedData = {
    eTitle: eventData.title,
    eDate: eventData.start,
    eDescription: eventData.description,
    eLink: eventData.link,
    Admin_idAdmin: currentAdminId,
    // note: additionals
    ePaymentMIGS: eventData.priceMIGS,
    ePaymentNonMIGS: eventData.priceNonMIGS,
    eCompetance: eventData.competance,
    eProgramNum: eventData.programNum,
    eUnits: eventData.units,
    StartTime: sTime,
    EndTime: eTime,
  };
  console.log(cleanedData);
  return async (dispatch) => {
    MySwal.fire({
      title: 'Creating event...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();
        const iCreateEvent = async () => {
          await api
            .post('/event/create', cleanedData)
            .then(async (res) => {
              //succsful request
              console.log(res);
              Swal.close({
                isSuccessful: true,
                message: 'Event successfuly created!',
              });
              await createAdminLog(
                `${currentAdminName} created the ${cleanedData.eTitle} event.`,
                currentAdminId
              );
            })
            .catch((err) => {
              //error
              console.log(err);
              //if invalid token, send the user back to default page
              if (err === INVALID_TOKEN) {
                dispatch({ type: actionTypes.RESET_USER });
              }
              //display the error to the user
              Swal.close({ isSuccessful: false, message: err });
            });
        };
        await iCreateEvent();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};
export const updateEvent = (
  eventData,
  currentAdminId,
  eventsList,
  currentAdminName
) => {
  let cleanedData;
  let sTime;
  let eTime;

  try {
    sTime = eventData.startTime.format('hh:mm a');
    eTime = eventData.endTime.format('hh:mm a');
  } catch (err) {
    sTime = eventData.startTime;
    eTime = eventData.endTime;
  }
  // clean the data
  cleanedData = {
    eTitle: eventData.title,
    idEvent: eventData.id,
    eDate: eventData.start,
    eDescription: eventData.description,
    eLink: eventData.link,
    Admin_idAdmin: currentAdminId,
    //Note: Additionals
    ePaymentMIGS: eventData.priceMIGS,
    ePaymentNonMIGS: eventData.priceNonMIGS,
    eCompetance: eventData.competance,
    eProgramNum: eventData.programNum,
    eUnits: eventData.units,
    StartTime: sTime,
    EndTime: eTime,
  };
  //create new events list and save it to the redux store
  const eventIndex = eventsList.findIndex(
    (item) => item.idEvent === eventData.id
  );
  let updatedEvents;
  const existingEvent = eventsList[eventIndex];
  const updatedEvent = {
    ...existingEvent,
    eTitle: eventData.title,
    eDate: eventData.start,
    eDescription: eventData.description,
    eLink: eventData.link,
    ePayment: eventData.price,
    Admin_idAdmin: currentAdminId,
    //Note: Additionals
    ePaymentMIGS: eventData.priceMIGS,
    ePaymentNonMIGS: eventData.priceNonMIGS,
    eCompetance: eventData.competance,
    eProgramNum: eventData.programNum,
    eUnits: eventData.units,
    StartTime: sTime,
    EndTime: eTime,
  };
  updatedEvents = [...eventsList];
  updatedEvents[eventIndex] = updatedEvent;

  return async (dispatch) => {
    MySwal.fire({
      title: 'Updating event...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();
        const iUpdateEvent = async () => {
          await api
            .put('/event/update', cleanedData)
            .then(async (res) => {
              //succesful request
              dispatch({ type: actionTypes.SET_EVENTS, data: updatedEvents });
              Swal.close({
                isSuccessful: true,
                message: 'Event successfuly updated!',
              });
              await createAdminLog(
                `${currentAdminName} updated the ${eventData.title} event`,
                currentAdminId
              );
            })
            .catch((err) => {
              //error
              console.log(err);
              //if invalid token, send the user back to default page
              if (err === INVALID_TOKEN) {
                dispatch({ type: actionTypes.RESET_USER });
              }
              //display the error to the user
              Swal.close({ isSuccessful: false, message: err });
            });
        };
        await iUpdateEvent();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};

export const deleteEvent = (id, events, currentAdminId, currentAdminName) => {
  const newEventsList = events.filter((event) => event.idEvent !== id);
  const event = events.filter((event) => event.idEvent === id);
  return async (dispatch) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this event?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        MySwal.fire({
          title: 'Deleting event...',
          showCancelButton: false,
          allowOutsideClick: false,
          timer: 100000000000,
          stopKeydownPropagation: true,
          didOpen: async () => {
            Swal.showLoading();
            const iDeleteEvent = async () => {
              await api
                .delete('/event/delete', { data: { idEvent: id } })
                .then(async (res) => {
                  //successful request
                  dispatch({
                    type: actionTypes.SET_EVENTS,
                    data: newEventsList,
                  });
                  Swal.close({
                    isSuccessful: true,
                    message: 'Event successfuly deleted!',
                  });
                  await createAdminLog(
                    `${currentAdminName} deleted the ${event[0].eTitle} event.`,
                    currentAdminId
                  );
                })
                .catch((err) => {
                  //error
                  //if invalid token, send the user back to default page
                  if (err === INVALID_TOKEN) {
                    dispatch({ type: actionTypes.RESET_USER });
                  }
                  Swal.close({ isSuccessful: false, message: err });
                });
            };
            await iDeleteEvent();
          },
        }).then((result) => {
          if (!result.isSuccessful) {
            MySwal.fire({
              title: 'Error',
              text: result.message,
              type: 'error',
            });
          } else if (result.isSuccessful) {
            MySwal.fire({
              title: 'Successful',
              text: result.message,
              type: 'success',
            });
          }
        });
      } else {
        return MySwal.fire('', 'Action cancelled!', 'error');
      }
    });
  };
};

export const createUserEvent = (idUser, idEvent) => {
  const body = {
    idUser,
    idEvent,
  };

  return async (dispatch) => {
    MySwal.fire({
      title: 'Creating user event...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();
        const sendRequest = async () => {
          await api
            .post('/userevent/create', body)
            .then((res) => {
              const message = res.data.message;
              Swal.close({ isSuccessful: true, message: message });
            })
            .catch((err) => {
              if (err === INVALID_TOKEN) {
                dispatch({ type: actionTypes.RESET_USER });
              }
              Swal.close({ isSuccessful: false, message: err });
            });
        };
        await sendRequest();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};
