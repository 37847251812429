import React from 'react';

// const SignIn1 = React.lazy(() =>
//   import('./Demo/Authentication/SignIn/SignIn1')
// );
const Login = React.lazy(() => import('./pages/Login/Login'));
const AdminLogin = React.lazy(() => import('./pages/Login/AdminLogin'));
const LandingPage = React.lazy(() => import('./pages/Landing/MainPage'));

const route = [
  {
    path: '/sign-in',
    exact: true,
    name: 'Login',
    component: Login,
  },
  {
    path: '/admin/sign-in',
    exact: true,
    name: 'Admin Login',
    component: AdminLogin,
  },
  {
    path: '/',
    exact: true,
    name: 'LandingPage',
    component: LandingPage,
  },
];

export default route;
