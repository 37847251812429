const DEMO = {
  BLANK_LINK: '#!',
};

export const INVALID_TOKEN = 'Invalid Token!';
export const INVALID_PASSWORD = 'Invalid Password';
export const MIGS = 'migs';
export const NON_MIGS = 'non-migs';
export const PICPA_TOKEN = 'PICPA_TOKEN';
export const ROLE = 'ROLE';
export const NO_USER_FOUND = 'No User Found!';
export const NO_ADMIN_FOUND = 'No Admin Found!';
export const NO_EVENTS_FOUND = 'No Events Found!';
export const APPROVE_MEMBER = 'approve';
export const DECLINE_MEMBER = 'decline';
export default DEMO;
