import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://api.picpabbmp.com',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

export const deleteLocalData = () => {
  localStorage.clear();
};
