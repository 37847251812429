import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const sweetAlertHandler = ({ title, text, type = 'info' }) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: title,
    text: text,
    type: type,
  });
};
