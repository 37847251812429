import * as actionTypes from './actions';
import { api } from './api';
import { INVALID_TOKEN, NO_ADMIN_FOUND, ROLE } from './constant';
import { createAdminLog } from './actions-logs';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export const updateAdmin = (
  data,
  currentAdminId,
  adminsList,
  currentAdminName
) => {
  const unchangedAdminName = data.adminName;
  //check if updating own acc
  const isUpdatingOwnAccount = data.idAdmin === currentAdminId;
  //create new admins list to be stored in redux.
  const newAdminData = { ...data };
  const adminIndex = adminsList.findIndex(
    (admin) => admin.idAdmin === newAdminData.idAdmin
  );
  let updatedAdmins;
  const existingAdmin = adminsList[adminIndex];
  const updatedAdmin = {
    ...existingAdmin,
    ...newAdminData,
  };
  updatedAdmins = [...adminsList];
  updatedAdmins[adminIndex] = updatedAdmin;

  //Clean the data first before putting it as the body;
  let cleanedData = data;
  delete cleanedData['adminName'];
  delete cleanedData['createdAt'];
  delete cleanedData['updatedAt'];
  delete cleanedData['adminPass'];

  return async (dispatch) => {
    MySwal.fire({
      title: 'Updating profile...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();
        const iUpdateAdmin = async () => {
          await api
            .put(`/admin/update`, cleanedData)
            .then(async (res) => {
              //succesful request
              dispatch({ type: actionTypes.SET_ADMINS, data: updatedAdmins });
              if (isUpdatingOwnAccount) {
                //updating own account
                console.log('Updating own acc');
                dispatch({
                  type: actionTypes.SET_USER_DATA,
                  data: updatedAdmin,
                });
                await createAdminLog(
                  `${currentAdminName} updated his/her profile.`,
                  currentAdminId
                );
              } else {
                await createAdminLog(
                  `${currentAdminName} updated the profile of admin ${unchangedAdminName}.`,
                  currentAdminId
                );
              }
              Swal.close({
                isSuccessful: true,
                message: 'Admin data updated!',
              });
            })
            .catch((err) => {
              //error
              console.log(err);
              //if invalid token, send the user back to default page
              if (err === INVALID_TOKEN) {
                dispatch({ type: actionTypes.RESET_USER });
              }
              //display the error to the user
              Swal.close({ isSuccessful: false, message: err });
            });
        };
        await iUpdateAdmin();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};

export const fetchAdminData = () => {
  return async (dispatch) => {
    const ifetchUserData = async () => {
      await api
        .get(`/admin/profile`)
        .then((res) => {
          //succesful request
          const userData = res.data;
          if (userData === NO_ADMIN_FOUND) {
            return dispatch({ type: actionTypes.RESET_USER });
          }
          dispatch({ type: actionTypes.SET_USER_DATA, data: userData });
          localStorage.setItem(ROLE, userData.role);
        })
        .catch((err) => {
          //error
          console.log(err);
          //if invalid token, send the user back to default page
          dispatch({ type: actionTypes.RESET_USER });
        });
    };

    await ifetchUserData();
  };
};

export const createAdmin = (data, currentAdminId, currentAdminName) => {
  //double check the value of data. Clean first before putting it as the body of the request.
  let cleanedData = {
    ...data,
  };
  delete cleanedData['idAdmin'];

  return async (dispatch) => {
    MySwal.fire({
      title: 'Creating admin account...',
      showCancelButton: false,
      allowOutsideClick: false,
      timer: 100000000000,
      stopKeydownPropagation: true,
      didOpen: async () => {
        Swal.showLoading();
        const iCreateAdmin = async () => {
          await api
            .post(`/admin/create`, cleanedData)
            .then(async (res) => {
              //succesful request
              const response = res;
              console.log(response);
              Swal.close({ isSuccessful: true, message: 'Admin created.' });
              await createAdminLog(
                `${currentAdminName} created the admin account ${cleanedData.adminName}`,
                currentAdminId
              );
            })
            .catch((err) => {
              //error
              console.log(err);
              //if invalid token, send the user back to default page
              if (err === INVALID_TOKEN) {
                dispatch({ type: actionTypes.RESET_USER });
              }
              //display the error to the user
              Swal.close({ isSuccessful: false, message: err });
            });
        };

        await iCreateAdmin();
      },
    }).then((result) => {
      if (!result.isSuccessful) {
        MySwal.fire({
          title: 'Error',
          text: result.message,
          type: 'error',
        });
        console.log('failed');
      } else if (result.isSuccessful) {
        MySwal.fire({
          title: 'Successful',
          text: result.message,
          type: 'success',
        });
        console.log('successful');
      }
    });
  };
};

export const fetchAdmins = () => {
  return async (dispatch) => {
    const iFetchAdmins = async () => {
      await api
        .get('/admin')
        .then((res) => {
          //succesful request
          const admins = res.data.data;
          dispatch({ type: actionTypes.SET_ADMINS, data: admins || [] });
          console.log('Admins fetched successfuly!');
          console.log(admins);
        })
        .catch((err) => {
          //error
          console.log(err);
          //if invalid token, send the user back to default page
          if (err === INVALID_TOKEN) {
            dispatch({ type: actionTypes.RESET_USER });
          }
        });
    };

    await iFetchAdmins();
  };
};

export const deleteAdmin = (id, admins, currentAdminName, currentAdminId) => {
  const adminAccount = admins.filter((admin) => admin.idAdmin === id);
  const newAdminsList = admins.filter((admin) => admin.idAdmin !== id);

  return async (dispatch) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this admin?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        MySwal.fire({
          title: 'Deleting admin...',
          showCancelButton: false,
          allowOutsideClick: false,
          timer: 100000000000,
          stopKeydownPropagation: true,
          didOpen: async () => {
            Swal.showLoading();
            const iDeleteAdmin = async () => {
              await api
                .delete('/admin/delete', { data: { idAdmin: id } })
                .then(async (res) => {
                  //succesful request

                  dispatch({
                    type: actionTypes.SET_ADMINS,
                    data: newAdminsList,
                  });
                  Swal.close({
                    isSuccessful: true,
                    message: 'Admin successfuly deleted!',
                  });
                  await createAdminLog(
                    `${currentAdminName} deleted the admin account of ${adminAccount[0].adminName}.`,
                    currentAdminId
                  );
                })
                .catch((err) => {
                  //error
                  console.log(`Delete member failed, error message: ${err}`);
                  //if invalid token, send the user back to default page
                  if (err === INVALID_TOKEN) {
                    dispatch({ type: actionTypes.RESET_USER });
                  }
                  Swal.close({ isSuccessful: false, message: err });
                });
            };
            await iDeleteAdmin();
          },
        }).then((result) => {
          if (!result.isSuccessful) {
            MySwal.fire({
              title: 'Error',
              text: result.message,
              type: 'error',
            });
          } else if (result.isSuccessful) {
            MySwal.fire({
              title: 'Successful',
              text: result.message,
              type: 'success',
            });
          }
        });
      } else {
        return MySwal.fire('', 'Action cancelled!', 'error');
      }
    });
  };
};
