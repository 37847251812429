import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import FullCalendar from 'fullcalendar-reactwrapper';
import Aux from '../../hoc/_Aux';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { sweetAlertHandler } from '../../components/Alert/SweetAlert';
import INIT_EVENTS from '../../dummy-data/DummyEvents';
import moment from 'moment';
import Add from '../../components/Add/Add';

const finished = '#1de9b6';
const notYetFinished = '#04a9f5';

export const convertMomentToString = (momentObject) => {
  return moment(momentObject).format('YYYY-MM-DD');
};

export const convertEpochToString = (epochTime) => {
  var date = new Date(epochTime);
  var fdate =
    date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  return fdate;
};
//check if the moment time is valid
export const isTimeValid = (momentTime) => {
  try {
    const time = momentTime.format('hh:mm a');
    console.log(time);
    return true;
  } catch (e) {
    return false;
  }
};
//check if the string date is valid // format: YYYY-MM-DD
export const isDateValid = (stringDate) => {
  try {
    const fMoment = moment(stringDate);
    console.log(fMoment);
    return true;
  } catch (err) {
    return false;
  }
};

export const convertStringToEpoch = (stringTime) => {
  let epoch = new Date(stringTime).getTime();
  return epoch;
};

export const getCurrentTime = () => {
  var d = new Date();
  return d.getTime() - d.getMilliseconds();
};

export const isEventInTheFuture = (eventDate) => {
  return convertStringToEpoch(eventDate) > getCurrentTime();
};

const Fullcalendar = ({ editable = false, selectable = false, eventsList }) => {
  const dispatch = useDispatch();
  const idAdmin = useSelector((state) => state.userData.idAdmin);

  const transformEvents = (eventsList) => {
    const fEventsList = eventsList.map((event) => {
      return {
        id: event.idEvent,
        priceNonMIGS: event.ePaymentNonMIGS,
        priceMIGS: event.ePaymentMIGS,
        title: event.eTitle,
        description: event.eDescription,
        start: event.eDate,
        link: event.eLink,
        startTime: event.StartTime,
        endTime: event.EndTime,
        idAdmin: event.Admin_idAdmin,
        competance: event.eCompetance,
        units: event.eUnits,
        programNum: event.eProgramNum,
        borderColor:
          convertStringToEpoch(event.eDate) < getCurrentTime()
            ? finished
            : notYetFinished,
        backgroundColor:
          convertStringToEpoch(event.eDate) < getCurrentTime()
            ? finished
            : notYetFinished,
        textColor: '#fff',
      };
    });
    console.log(fEventsList);
    return fEventsList;
  };

  const [events, setevents] = useState([]);
  useEffect(() => {
    setevents(transformEvents(eventsList));
  }, [eventsList]);

  const handleEventClick = (clickInfo) => {
    console.log(clickInfo);
    dispatch({ type: actionTypes.SHOW_MODAL, data: clickInfo });
  };

  const handleSelect = (selectInfo) => {
    console.log(selectInfo.format()); //Sample output = 2021-07-23
    const date = selectInfo.format();
    if (isEventInTheFuture(date)) {
      dispatch({
        type: actionTypes.SHOW_MODAL,
        data: { start: date, id: null },
      });
    } else {
      console.log('Bawal bumalik sa nakaraan.');
      sweetAlertHandler({
        title: 'Invalid Action!',
        icon: 'error',
      });
    }
  };

  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Events Calendar</Card.Title>
            </Card.Header>
            <Card.Body className="calendar">
              {idAdmin && (
                <Add
                  style={{ paddingBottom: '15px' }}
                  onClick={() => {
                    dispatch({
                      type: actionTypes.SHOW_MODAL,
                      data: { id: null },
                    });
                  }}
                  title="Create event"
                />
              )}

              <hr style={{ width: '100%', borderTop: '1px solid #e4e4e4' }} />
              <FullCalendar
                id="datta-calendar"
                className="calendar"
                header={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'month,basicWeek,basicDay',
                }}
                eventClick={handleEventClick}
                // defaultDate={today}
                navLinks={true}
                selectable={selectable}
                editable={editable}
                eventLimit={true}
                events={events}
                select={handleSelect}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
};

export default Fullcalendar;
